import React, { FunctionComponent, createRef } from 'react';
import Layout from '../components/Layout';
import MetaData from '../components/MetaData';
import ExternalLink from '../components/ExternalLink';
import ReloadButton from '../components/ReloadButton';
import Address from '../components/Address';
import Network from '../components/Network';
import { Row, Col, Table, Input, Button, Typography, Tooltip } from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { FAUCET_ADDRESS, FAUCET_NETWORKS } from '../data/faucet';
import { NetworkTransaction } from '../types/transaction';
import { useData } from '../hooks';
import { utils } from 'ethers';
import dayjs from '../utils/dayjs';

import '../sass/index.scss';

const { Title } = Typography;

interface TransactionTableItem extends NetworkTransaction {
    key: number;
}

const Transactions: FunctionComponent = () => {
    const { loading, parsing, transactions, updateData } = useData();
    const searchInput = createRef<Input>();

    return (
        <Layout>
            <MetaData />
            <Row>
                <Col offset={2} span={20}>
                    <Title level={2}>Transactions</Title>
                    <ReloadButton loading={loading} action={updateData} />
                    <Table
                        loading={
                            parsing
                                ? {
                                      indicator: (
                                          <LoadingOutlined
                                              style={{ fontSize: '40px' }}
                                              spin={true}
                                          />
                                      )
                                  }
                                : false
                        }
                        columns={[
                            {
                                title: 'Network',
                                dataIndex: 'network',
                                filters: FAUCET_NETWORKS.map(network => {
                                    return {
                                        text: <Network type={network} />,
                                        value: network
                                    };
                                }),
                                onFilter: (value, entry) => entry.network === value,
                                render: network => <Network type={network} />
                            },
                            {
                                title: 'Transaction',
                                dataIndex: 'hash',
                                render: (txHash, entry) => (
                                    <ExternalLink
                                        to={`https://${entry.network}.etherscan.io/tx/${txHash}`}
                                    >
                                        {txHash.substr(0, 12)}...
                                    </ExternalLink>
                                )
                            },
                            {
                                title: 'Address',
                                dataIndex: 'to',
                                filterDropdown: ({
                                    setSelectedKeys,
                                    selectedKeys,
                                    confirm,
                                    clearFilters
                                }) => (
                                    <div style={{ padding: 8 }}>
                                        <Input
                                            ref={searchInput}
                                            placeholder={`Search address`}
                                            value={selectedKeys[0]}
                                            onChange={e =>
                                                setSelectedKeys(
                                                    e.target.value ? [e.target.value] : []
                                                )
                                            }
                                            onPressEnter={confirm}
                                            style={{
                                                width: 188,
                                                marginBottom: 8,
                                                display: 'block'
                                            }}
                                        />
                                        <Button
                                            type="primary"
                                            onClick={confirm}
                                            icon={<SearchOutlined />}
                                            size="small"
                                            style={{ width: 90, marginRight: 8 }}
                                        >
                                            Search
                                        </Button>
                                        <Button
                                            onClick={clearFilters}
                                            size="small"
                                            style={{ width: 90 }}
                                        >
                                            Reset
                                        </Button>
                                    </div>
                                ),
                                filterIcon: filtered => (
                                    <SearchOutlined
                                        style={{ color: filtered ? '#1890ff' : undefined }}
                                    />
                                ),
                                onFilter: (value, record) =>
                                    record.to
                                        .toString()
                                        .toLowerCase()
                                        .includes(value.toString().toLowerCase()),
                                onFilterDropdownVisibleChange: visible => {
                                    if (visible) {
                                        setTimeout(() => {
                                            if (searchInput.current) {
                                                searchInput.current.select();
                                            }
                                        });
                                    }
                                },

                                render: (address, entry) => (
                                    <Address address={address} network={entry.network} />
                                )
                            },
                            {
                                title: 'Value',
                                dataIndex: 'value',
                                render: value => <span>{utils.formatEther(value)} ETH</span>,
                                sorter: (a, b) => parseInt(b.value, 10) - parseInt(a.value, 10)
                            },
                            {
                                title: 'Date',
                                dataIndex: 'timeStamp',
                                render: timestamp => (
                                    <Tooltip title={dayjs(timestamp * 1000).format('LLL')}>
                                        {dayjs(timestamp * 1000).fromNow()}
                                    </Tooltip>
                                ),
                                sorter: (a, b) =>
                                    parseInt(b.timeStamp, 10) - parseInt(a.timeStamp, 10)
                            }
                        ]}
                        dataSource={transactions
                            .filter(tx => {
                                return (
                                    tx.from &&
                                    tx.from.toLowerCase() === FAUCET_ADDRESS.toLowerCase()
                                );
                            })
                            .map((tx, index) => {
                                const tableItem: TransactionTableItem = {
                                    key: index,
                                    ...tx
                                };
                                return tableItem;
                            })
                            .sort((a, b) => parseInt(b.timeStamp, 10) - parseInt(a.timeStamp, 10))}
                    />
                </Col>
            </Row>
        </Layout>
    );
};

export default Transactions;
