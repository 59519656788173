import React, { FunctionComponent, Fragment } from 'react';
import ExternalLink from '../ExternalLink';
import makeBlockie from 'ethereum-blockies-base64';
import { utils } from 'ethers';

interface Props {
    address: string;
    network?: string;
}

const Address: FunctionComponent<Props> = ({ address, network }) => {
    const checksumAddress = utils.getAddress(address);
    return (
        <Fragment>
            <ExternalLink
                to={`https://${
                    network ? `${network}.` : ''
                }etherscan.io/address/${checksumAddress}`}
            >
                <img
                    style={{
                        width: '16px',
                        height: '16px',
                        borderRadius: '50%',
                        verticalAlign: 'text-bottom'
                    }}
                    src={makeBlockie(checksumAddress)}
                />{' '}
                <span className="ng-binding" style={{ marginLeft: '5px' }}>
                    {checksumAddress}
                </span>
            </ExternalLink>
        </Fragment>
    );
};

export default Address;
